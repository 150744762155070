import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image = ({src}) => {    
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "piggy-bank.png" }) {
        childImageSharp {
          fixed(width: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
    return <Img fixed={data.placeholderImage.childImageSharp.fixed} />;
};

export default Image;
